<template>
  <ValidationObserver ref="paymentPage">
    <div class="card card-custom">
      <div class="card-body container">

        <b-alert v-if="errorAlert" variant="warning" show>
          <div class="text-dark" v-html="errorAlert"></div>
        </b-alert>

        <b-card class="mb-5" border-variant="primary" body-class="px-5 py-5" v-if="paymentData">
          <h3 class="font-weight-bold text-dark text-center">
            Invoice Details
          </h3>

          <div class="form-group mb-0">
            <label class="col-sm-2 font-size-h6 col-form-label">Invoice Number</label>
            <label class="col-sm-4 font-size-h6 col-form-label font-weight-bold">
              {{ paymentData.invoice.K_InvoiceNumber }}
            </label>
            <label class="col-sm-2 font-size-h6 col-form-label">Invoice Amount</label>
            <label class="col-sm-4 font-size-h6 col-form-label font-weight-bold">
              {{ paymentData.invoice.amount | currency }}
            </label>
            <label class="col-sm-2 font-size-h6 col-form-label">Member Name</label>
            <label class="col-sm-4 font-size-h6 col-form-label font-weight-bold">
              {{ paymentData.member.Account_Name }}
            </label>
            <label class="col-sm-2 font-size-h6 col-form-label">Membership Number</label>
            <label class="col-sm-4 font-size-h6 col-form-label font-weight-bold">
              {{ paymentData.member.Membership_No }}
            </label>
          </div>

          <div class="d-flex justify-content-center" v-if="paymentData.invoice.K_InvoicePdfLink">
            <a class="btn btn-primary font-weight-bold text-uppercase px-4 py-3"
              target="_blank" :href="paymentData.invoice.K_InvoicePdfLink">
              View Invoice
            </a>
          </div>
        </b-card>

        <div v-if="isPaymentAllowed">
          <div class="text-center mt-10 mb-5">
            <label class="font-size-h4 text-dark font-weight-bold">
              Choose Payment Method
            </label>
          </div>

          <div class="d-flex justify-content-center">
            <div class="col-sm-3">
              <label class="option" :class="{ 'selected-payment-option' : (paymentMethod === 'CREDIT_DEBIT_CARD') }">
                <span class="option-control">
                  <span class="radio">
                    <input type="radio" name="paymentMethod" value="CREDIT_DEBIT_CARD" v-model="paymentMethod"/>
                    <span></span>
                  </span>
                </span>
                <span class="option-label">
                  <span class="option-head">
                    <span class="option-title">
                      <strong>Credit/Debit Card</strong>
                    </span>
<!--                    <span class="option-focus">-->
<!--                    </span>-->
                  </span>
                  <span class="option-body">
                    <img src="/media/logos/Credit_Card_Logos.png" alt="Credit/Debit Card" class="img-fluid" style="max-height: 40px;"/>
                  </span>
                </span>
              </label>
            </div>
            <div class="col-sm-3">
              <label class="option" :class="{ 'selected-payment-option' : (paymentMethod === 'CAPRICORN') }">
                <span class="option-control">
                  <span class="radio">
                    <input type="radio" name="paymentMethod" value="CAPRICORN" v-model="paymentMethod"/>
                    <span></span>
                  </span>
                </span>
                <span class="option-label">
                  <span class="option-head">
                    <span class="option-title">
                      <strong>Pay by Capricorn</strong>
                    </span>
<!--                    <span class="option-focus">-->
<!--                    </span>-->
                  </span>
                  <span class="option-body">
                    <img src="/media/logos/Capricorn_Logo.png" alt="Capricorn" class="img-fluid" style="max-height: 40px;"/>
                  </span>
                </span>
              </label>
            </div>
          </div>

          <div class="d-flex justify-content-center pt-5">
            <button class="btn btn-primary font-weight-bold text-uppercase px-4 py-4"
                    :disabled="!paymentMethod"
                    @click.prevent="goToPaymentTypeLink">
              Pay Now
            </button>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<style lang="scss">

body.swal2-shown > [aria-hidden="true"] {
  transition: 0.1s filter;
  filter: blur(10px);
}

.selected-payment-option {
  border: 1px solid #3699FF;
}
</style>

<script>
import {ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME} from "@/core/services/store/htmlclass.module";
import _ from "lodash";
import KTUtil from "@/assets/js/components/util";
import ApiService from "@/core/services/api.service";
// import Swal from "sweetalert2";

export default {
  name: "PaymentPage",

  components: {  },

  data() {
    return {
      serverUrl: null,
      errorAlert: null,

      invoiceUniqueId: null,
      paymentData: null,
      paymentMethod: null
    };
  },

  mounted() {
    this.startPageLoading();

    // Extract information from URL params
    let queryParams = this.$route.query;
    if (!queryParams.invoiceUniqueId) {
      this.handleError("Unauthorised access!");
      return;
    }

    // Extract data from URL params
    this.invoiceUniqueId = queryParams.invoiceUniqueId;
    // ApiService.saveTokenAndSetHeader(queryParams.token);

    this.serverUrl = `${window.location.origin}/services`;
    if(process.env.NODE_ENV !== 'production') {
      this.serverUrl = `http://localhost:3000/services`;
    }

    // Get invoice data
    this.getPaymentData();
  },

  computed: {
    isPaymentAllowed: function() {
      return this.paymentData
          && this.paymentData.invoice.K_InvoiceStatus === 'Open'
          && this.paymentData.invoice.amount > 0;
    }
  },

  methods: {

    getPaymentData: function () {
      if(!this.invoiceUniqueId) {
        throw `Invalid Invoice Unique ID!`
      }

      ApiService.get(`${this.serverUrl}/payments/${this.invoiceUniqueId}`)
        .then(({ data }) => {
          this.paymentData = data.result;

          // Set wizard title and subtitle
          this.$store.commit("setWizardTitle", `MTA NSW Payment`);
          this.$store.commit("setWizardSubtitle", this.paymentData.invoice.K_InvoiceNumber);

          this.stopPageLoading();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    goToPaymentTypeLink: async function () {
      this.clearError();

      if(!this.isPaymentAllowed) {
        return;
      }
      if(!this.paymentMethod) {
        this.setError('Please choose a Payment Method.');
        return;
      }

      this.startPageLoading();

      if(this.paymentMethod === 'CREDIT_DEBIT_CARD') {
        ApiService.get(`${this.serverUrl}/payments/${this.invoiceUniqueId}/cc-link`)
            .then(({ data }) => {
              let creditCardPaymentLink = data.result;
              if(creditCardPaymentLink) {
                this.goToLink(creditCardPaymentLink);
              }
            })
            .catch((error) => {
              this.handleError(error);
            });
      }
      else if(this.paymentMethod === 'CAPRICORN') {
        ApiService.get(`${this.serverUrl}/payments/${this.invoiceUniqueId}/capricorn-link`)
            .then(({ data }) => {
              let capricornPaymentLink = data.result;
              if(capricornPaymentLink) {
                this.goToLink(capricornPaymentLink);
              }
            })
            .catch((error) => {
              this.handleError(error);
            });
      }
    },

    goToLink: function(someLink) {
      let link = document.createElement("a");
      link.hidden = true;
      link.id = 'paymentLink';
      link.target = '_parent';
      link.href = someLink;

      document.body.appendChild(link);
      document.getElementById('paymentLink').click();
    },

    setError: function (message) {
      KTUtil.scrollTop();
      this.errorAlert = message;
    },

    clearError: function () {
      this.errorAlert = null;
    },

    handleError: function(errorObj) {
      this.stopPageLoading();
      let errorMsg = null;

      if(errorObj) {
        let responseStatus = _.get(errorObj, "response.status");
        let errorMessage = errorObj.message || '';
        if (responseStatus === 401 || errorMessage.includes("status code 401") ||
            responseStatus === 403 || errorMessage.includes("status code 403")
        ) {
          errorMsg = "This session is invalid or expired. Please close this window.";
        }
        else {
          let responseResult = _.get(errorObj, "response.data.result");
          if (_.isString(responseResult)) {
            let applicationErrorPrefix = "UI_ERROR:";

            if (responseResult.startsWith(applicationErrorPrefix)) {
              errorMsg = responseResult.substring(applicationErrorPrefix.length, responseResult.length);
            }
          }
        }
      }
      if (!errorMsg) {
        errorMsg = "An unexpected error has occurred.";
      }

      this.setError(errorMsg);
      KTUtil.scrollTop();
    },

    startPageLoading: function() {
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
    },

    stopPageLoading: function() {
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }
  },
};
</script>
